function Error () {
  return (
    <>
      <section className="message error">
        <div className="wrapper">
          <p>Failed to load data...</p>
        </div>
      </section>
    </>
  )
}

export default Error;
const Traffic = () => {
  return (
    <>
      <section id="admin_traffic">
        <div className="wrapper">
          <div className="content">

            <h1>Traffic</h1>



            <div className="view_list" style={{ textAlign: 'center'}}>
              Under Construction...
            </div>
            
          </div>
        </div>
      </section>
    </>
  )
}

export default Traffic;
